import { lazy } from 'react';
import Loadable from 'app/components/Loadable';

// const HomePage = Loadable(lazy(() => import('../home/pages/Start')));
// const SecondPage = Loadable(lazy(() => import('../home/pages/Two')));
const Main= Loadable(lazy(() => import('../home/pages/Main')));
const Item=Loadable(lazy(()=>import('../home/pages/Item')))
const CustomerPortal=Loadable(lazy(()=>import('../home/pages/CustomerPortal')))

const RealEstateRoutes = [
                        // { path: '/home', element: < HomePage /> },
                        // { path: '/two', element: <SecondPage /> },
                        { path: '/landing', element: <Main /> },
                        { path: '/landing/item/:id', element: <Item /> },
                        {path:'customerportal',element:<CustomerPortal/>}
];

export default RealEstateRoutes;
