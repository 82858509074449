import {combineReducers} from 'redux';
import storage from 'redux-persist/lib/storage';

import categoryReducer from 'app/features/real_estates_properties/home/slices/categorySlice';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  category:categoryReducer
});

export {rootPersistConfig, rootReducer};
