import { CssBaseline } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { MatxTheme } from './components';
import { AuthProvider } from './features/authentication/contexts/JWTAuthContext';
import { HomeProvider } from './features/real_estates_properties/home/context/HomeContext';
import { SettingsProvider } from './contexts/SettingsContext';
import routes from './routes';
import { Provider as ReduxProvider } from 'react-redux';
import {store} from './redux/store'
// import '../fake-db';

const App = () => {
  const content = useRoutes(routes);

  return (
    <SettingsProvider>
      <AuthProvider>
        <ReduxProvider store={store}>
          <HomeProvider>
            <MatxTheme>
              <CssBaseline />
              {content}
            </MatxTheme>
          </HomeProvider>
        </ReduxProvider>
      </AuthProvider>
    </SettingsProvider>
  );
};

export default App;
