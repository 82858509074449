import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import { useDispatch, useSelector } from 'react-redux';

// eslint-disable-next-line import/no-cycle
import { rootPersistConfig, rootReducer } from "./rootReducers";

const store = configureStore({
    reducer: persistReducer(rootPersistConfig, rootReducer),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false,
        }),
});

const persistor = persistStore(store);

const { dispatch } = store;


const useAppDispatcher = useDispatch;
const useAppSelector= useSelector;

export { dispatch, persistor, store, useAppDispatcher, useAppSelector };

