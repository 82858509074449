import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Replace this with your actual API endpoint for fetching ads
const ADS_API_URL = '/api/ads';

export const fetchCategoriesAndFilters = createAsyncThunk(
  'categories/fetchCategoriesAndFilters',
  async () => {
    try {
      const response = await fetch(ADS_API_URL);
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchAds = createAsyncThunk(
  'categories/fetchAds',
  async ({ categoryId, filters, location }) => {
    try {
      // Make a request to your ads API with the selected category, filters, and location
      const response = await fetch(`${ADS_API_URL}?category=${categoryId}&filters=${filters}&location=${location}`);
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
  categories: [],
  filters: [],
  ads: [],
  likedAds: [],
  pinnedAds: [],
  currentLevelx: 0,
  parent: 0,
  inputFilterValues: {},
  status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
  error: null,
  activeScreen: 0,
};

const categoriesSlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    selectCategory: (state, action) => {
      state.currentLevelx = action.payload.level;
      state.parent = action.payload.id;
    },
    setInputFilterValues: (state, action) => {
      state.inputFilterValues = action.payload;
    },
    addFilters: (state, action) => {
      state.filters = action.payload;
    },
    appendPinnedAds: (state, action) => {
      state.pinnedAds = state.pinnedAds.concat(action.payload);
    },

    togglePin: (state, action) => {
      const existingIndex = state.pinnedAds.findIndex(ad => ad === action.payload);
      console.log(existingIndex, 'existing index', action.payload);
      if (existingIndex === -1) {
        state.pinnedAds.push(action.payload);
      } else {
        state.pinnedAds = state.pinnedAds.filter(ad => ad !== action.payload);
      }
    },
    toggleFavorite: (state, action) => {
      const existingIndex = state.likedAds.findIndex(ad => ad === action.payload);
      console.log(existingIndex, 'existing index', action.payload);
      if (existingIndex === -1) {
        state.likedAds.push(action.payload);
      } else {
        state.likedAds = state.likedAds.filter(ad => ad !== action.payload);
      }
    },
    
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategoriesAndFilters.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCategoriesAndFilters.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.categories = action.payload.categories;
        state.filters = action.payload.filters;
      })
      .addCase(fetchCategoriesAndFilters.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchAds.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAds.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.ads = action.payload;
      })
      .addCase(fetchAds.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { selectCategory, setInputFilterValues, addFilters, appendPinnedAds, toggleFavorite, togglePin } = categoriesSlice.actions;

export default categoriesSlice.reducer;
